import React from "react"

//Imagens
import img from "./../../assets/imagens/bg-adote-3.png"
import img2 from "./../../assets/imagens/bg-adote-3.png"
import img3 from "./../../assets/imagens/bg-adote-3.png"
import img4 from "./../../assets/imagens/bg-adote-3.png"
import img5 from "./../../assets/imagens/bg-adote-3.png"
import img6 from "./../../assets/imagens/bg-adote-3.png"

import $ from "jquery"

function Gallery() {
    var count = 10;
    //escondemos todos os elementos maior que o valor inicial
    $("li").slice(count).hide();

    $('#carrega-mais').click(function () {

        //Somamos a quantidade nova a ser exibida
        count += 3;

        //Rodamos o loop no valor total
        for (var i = 0; i < count; i++) {
            //Mostramos o item
            $('li').eq(i).show();
        }
    });


    return (
        <>
            <div className="container-fluid bg-light py-5 wow fadeInUp" data-wow-delay="0.1s" id="gallery">
                <div className="container">
                    <div className="section-header mb-5 wow fadeInUp" data-wow-delay="0.3s">
                        <h1 className="display-5 text-center">🐶Galeria de Fotos🐱</h1>
                        <p className="text-center">Amada Associação Maranhnese em defesa dos animais.</p>
                    </div>

                    <div className="row g-4">
                        <ul className="text-center">
                            <li className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                <div>
                                    <div className="row g-4">
                                        <div className="col-12">
                                            <a className="gallery-item" href={img} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">Fotografia</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-12">
                                            <a className="gallery-item" href={img2} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img2} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">Cobertura Eventos</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-6 li wow fadeInUp" data-wow-delay="0.3s">
                                <div>
                                    <div className="row g-4">
                                        <div className="col-12">
                                            <a className="gallery-item" href={img3} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img3} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">Adoção</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-12">
                                            <a className="gallery-item" href={img4} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img4} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">Imagens</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-6 li wow fadeInUp" data-wow-delay="0.5s">
                                <div>
                                    <div className="row g-4">
                                        <div className="col-12">
                                            <a className="gallery-item" href={img5} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img5} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">Videmaker</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-12">
                                            <a className="gallery-item" href={img6} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img6} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">TerraZoo</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-6 li wow fadeInUp" data-wow-delay="0.5s">
                                <div>
                                    <div className="row g-4">
                                        <div className="col-12">
                                            <a className="gallery-item" href={img5} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img5} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">Videmaker</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-12">
                                            <a className="gallery-item" href={img6} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img6} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">TerraZoo</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            
                            <li className="col-lg-4 col-md-6 li wow fadeInUp" data-wow-delay="0.5s">
                                <div>
                                    <div className="row g-4">
                                        <div className="col-12">
                                            <a className="gallery-item" href={img5} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img5} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">Videmaker</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-12">
                                            <a className="gallery-item" href={img6} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img6} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">TerraZoo</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-6 li wow fadeInUp" data-wow-delay="0.5s">
                                <div>
                                    <div className="row g-4">
                                        <div className="col-12">
                                            <a className="gallery-item" href={img5} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img5} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">Videmaker</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-12">
                                            <a className="gallery-item" href={img6} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img6} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">TerraZoo</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-6 li wow fadeInUp" data-wow-delay="0.5s">
                                <div>
                                    <div className="row g-4">
                                        <div className="col-12">
                                            <a className="gallery-item" href={img5} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img5} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">Videmaker</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-12">
                                            <a className="gallery-item" href={img6} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img6} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">TerraZoo</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-6 li wow fadeInUp" data-wow-delay="0.5s">
                                <div>
                                    <div className="row g-4">
                                        <div className="col-12">
                                            <a className="gallery-item" href={img5} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img5} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">Videmaker</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-12">
                                            <a className="gallery-item" href={img6} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img6} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">TerraZoo</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-6 li wow fadeInUp" data-wow-delay="0.5s">
                                <div>
                                    <div className="row g-4">
                                        <div className="col-12">
                                            <a className="gallery-item" href={img5} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img5} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">Videmaker</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-12">
                                            <a className="gallery-item" href={img6} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img6} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">TerraZoo</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-6 li wow fadeInUp" data-wow-delay="0.5s">
                                <div>
                                    <div className="row g-4">
                                        <div className="col-12">
                                            <a className="gallery-item" href={img5} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img5} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">Videmaker</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-12">
                                            <a className="gallery-item" href={img6} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img6} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">TerraZoo</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-6 li wow fadeInUp" data-wow-delay="0.5s">
                                <div>
                                    <div className="row g-4">
                                        <div className="col-12">
                                            <a className="gallery-item" href={img5} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img5} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">Videmaker</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-12">
                                            <a className="gallery-item" href={img6} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img6} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">TerraZoo</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-6 li wow fadeInUp" data-wow-delay="0.5s">
                                <div>
                                    <div className="row g-4">
                                        <div className="col-12">
                                            <a className="gallery-item" href={img5} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img5} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">Videmaker</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="col-12">
                                            <a className="gallery-item" href={img6} data-lightbox="gallery">
                                                <div className="position-relative">
                                                    <img className="img-fluid" src={img6} alt="" />
                                                    <div className="gallery-text p-4">
                                                        <p className="text-white small text-uppercase mb-0">Amada</p>
                                                        <h5 className="text-white mb-0">TerraZoo</h5>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>

                    </div>

                    <div className="row py-5 g-5 align-items-end wow fadeInUp" data-wow-delay="0.1s">
                        <div className="text-center">
                            <button id="carrega-mais" className="btn btn-primary py-3 px-5">Veja Mais...</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Gallery