import React from "react"

function BannerInfo() {
    return (
        <>
            <div id="doacoes" className="container-fluid views-info bg-secondary bg-icon wow fadeIn" data-wow-delay="0.1s"></div>
        </>
    )
}

export default BannerInfo