import React from "react"
import { NavLink } from "react-router-dom"
import Navbar from "../components/Navbar/Navbar"
import Footer from "../components/Footer/Footer"
import ScrollToTop from "../components/BackToTop/ScrollToTop"
import Blog from "../components/Blog/Blog"

function Posts() {
  return (
    <>
      <Navbar />
      <div className="container-fluid bg-light page-header wow fadeIn" data-wow-delay="0.1s">
        <div className="container p-5">
          <h1 className="display-3 mb-3 animated slideInDown">Blog</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <NavLink className="text-primary" to="/">Home</NavLink>
              </li>
              <li className="breadcrumb-item text-body active" aria-current="page">
                Posts
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container-xxl py-3">
        <div className="container p-5">
          <Blog/>
        </div>
      </div>

      <Footer/>
      <ScrollToTop/>

    </>
  )
}

export default Posts