import React from "react"
import { NavLink } from "react-router-dom"

//Imagens
import logo from "./../../assets/imagens/logo-br2.png"
import devlogo from "./../../assets/imagens/logo-name.png"
import adote from "./../../assets/imagens/bg-adote.png"


function Footer() {
    return (
        <>
            <div className="container-fluid bg-footer text-light footer wow fadeIn" data-wow-delay="0.1s">
                <div className="footer-barra"></div>
                <div className="container p-2 px-lg-5">
                    <div className="row g-4">
                        <div className="col-md-6 col-lg-3">
                            <p className="section-title text-white h5 mb-4">
                                Endereço<span></span>
                            </p>
                            <p>
                                <img className="img-fluid p-4 me-3" src={logo} alt="logo footer" />
                            </p>
                            <a href="mailto:amada.sl@gmail.com">
                                <i className="fa fa-envelope me-3"></i>
                                amada.sl@gmail.com
                            </a>
                            <div className="d-flex pt-2">
                                <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-outline-light btn-social" href="https://www.facebook.com/AmadaSlz" target="_blank"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-outline-light btn-social" href="https://www.instagram.com/amadasaoluis" target="_blank"><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <p className="section-title text-white h5 mb-4">
                                Quick Link<span></span>
                            </p>
                            <a className="btn btn-link" href="/">Sobre Nós</a>
                            <a className="btn btn-link" href="/contato">Contato</a>
                            <a className="btn btn-link" href="/">Politica de Privacidade</a>
                            <a className="btn btn-link" href="/">Termos & Condicões</a>
                            <a className="btn btn-link" href="/">Voluntários</a>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <p className="section-title text-white h5 mb-4">
                                Instagram<span></span>
                            </p>
                            <div className="row g-2">
                                <div className="col-4">
                                    <img className="img-fluid" src={adote} alt="Image"/>
                                </div>
                                <div className="col-4">
                                    <img className="img-fluid" src={adote} alt="Image"/>
                                </div>
                                <div className="col-4">
                                    <img className="img-fluid" src={adote} alt="Image"/>
                                </div>
                                <div className="col-4">
                                    <img className="img-fluid" src={adote} alt="Image"/>
                                </div>
                                <div className="col-4">
                                    <img className="img-fluid" src={adote} alt="Image"/>
                                </div>
                                <div className="col-4">
                                    <img className="img-fluid" src={adote} alt="Image"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <p className="section-title text-white h5 mb-4">
                                Parceiros<span></span>
                            </p>
                            <section className="stage" id="menuNav">
                                <div id="home" className="menu"><p id="homep" className="p">TerraZoo</p></div>
                                <div className="menu" id="amada"><p className="p">Fulano</p></div>
                                <div className="menu" id="contact"><p className="p">Fulano</p></div>
                                <div className="menu" id="gallery"><p className="p">Fulano</p></div>
                            </section>
                        </div>
                    </div>
                </div>

                <div className="container px-lg-5">
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                &copy; 
                                    <NavLink to="/">AmadaSLZ</NavLink>
                                , Direitos reservados | Organização não governamental.
                            </div>
                            <div className="col-md-6 text-center text-md-end">
                                <div className="footer-menu">
                                    Desenvolvido por: &nbsp;
                                    <a href="https://adrianalima.vercel.app">
                                        <img className="logo-rodape" src={devlogo} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer