import React, { useEffect, useState } from "react"


function ScrollToTop() {

    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);

    const backToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };


    return (
        <>
            <div className="btn btn-lg btn-secondary btn-lg-square back-to-top">
                {""}
                {showTopBtn && (
                    <i className="bi bi-arrow-up icon-position icon-style" onClick={backToTop}></i>
                )}{""}
            </div>
        </>
    )
}

export default ScrollToTop