import React, {  } from "react"

//Imagens
import post from "./../../assets/imagens/blog/318620216_5992203230838000_6089979890799413399_n.jpg"
import post2 from "./../../assets/imagens/blog/319887299_1641716936278854_931877671883021797_n.jpg"
import post3 from "./../../assets/imagens/blog/bg-adote2.png"

import $ from "jquery"


function Blog() {

    var count = 16;
    //escondemos todos os elementos maior que o valor inicial
    $("li").slice(count).hide();

    $('#carrega-mais').click(function () {

        //Somamos a quantidade nova a ser exibida
        count += 3;

        //Rodamos o loop no valor total
        for (var i = 0; i < count; i++) {
            //Mostramos o item
            $('li').eq(i).show();
        }
    });

    return (
        <>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "500px" }}>
                        <h1 className="display-5 mb-3"> 🐈 Blog  🐕 </h1>
                        <p>
                            Tempor ut dolore lorem kasd vero ipsum sit eirmod sit. Ipsum diam
                            justo sed rebum vero dolor duo.
                        </p>
                    </div>

                    <div className="row g-4">
                        <ul>
                            <li className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="product-item shadow ">
                                    <img className="img-fluid mt-2 w-100" src={post} alt="" />
                                    <div className="p-4">
                                        <a className="d-block h5 lh-base mb-4" href="">Amada compareceu ao evento de solenidade...</a>
                                        <p>
                                            Hoje, a Amada compareceu ao evento de solenidade da
                                            @prefeiturasaoluis com @eduardobraide e outros órgãos e entidades,
                                            para a assinatura da ordem de serviço da construção do Hospital
                                            Veterinário Municipal...
                                        </p>
                                        <div className="text-muted border-top pt-4">
                                            <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                            <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>07 Dez, 2022</small>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="product-item shadow">
                                    <img className="img-fluid mt-2 w-100" src={post2} alt="" />

                                    <div className="p-4">
                                        <a className="d-block h5 lh-base mb-4" href="">Desejo da Amada neste Natal...</a>
                                        <p>Feliz Natal a todos os amigos e voluntários da Amada.</p>
                                        <div className="text-muted border-top pt-4">
                                            <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                            <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>25 Dez, 2022</small>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="product-item shadow ">
                                    <img className="img-fluid mt-2 w-100" src={post3} alt="" />

                                    <div className="p-4">
                                        <a className="d-block h5 lh-base mb-4" href="">Campanha Adote-me, será realizada neste mês...</a>
                                        <p>How to cultivate organic fruits and vegetables in own firm</p>
                                        <div className="text-muted border-top pt-4">
                                            <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                            <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>01 Jan, 2023</small>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="product-item shadow ">
                                    <img className="img-fluid mt-2 w-100" src={post3} alt="" />

                                    <div className="p-4">
                                        <a className="d-block h5 lh-base mb-4" href="">Campanha Adote-me, será realizada neste mês...</a>
                                        <p>How to cultivate organic fruits and vegetables in own firm</p>
                                        <div className="text-muted border-top pt-4">
                                            <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                            <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>01 Jan, 2023</small>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="product-item shadow ">
                                    <img className="img-fluid mt-2 w-100" src={post3} alt="" />

                                    <div className="p-4">
                                        <a className="d-block h5 lh-base mb-4" href="">Campanha Adote-me, será realizada neste mês...</a>
                                        <p>How to cultivate organic fruits and vegetables in own firm</p>
                                        <div className="text-muted border-top pt-4">
                                            <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                            <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>01 Jan, 2023</small>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="product-item shadow ">
                                    <img className="img-fluid mt-2 w-100" src={post3} alt="" />

                                    <div className="p-4">
                                        <a className="d-block h5 lh-base mb-4" href="">Campanha Adote-me, será realizada neste mês...</a>
                                        <p>How to cultivate organic fruits and vegetables in own firm</p>
                                        <div className="text-muted border-top pt-4">
                                            <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                            <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>01 Jan, 2023</small>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="product-item shadow ">
                                    <img className="img-fluid mt-2 w-100" src={post3} alt="" />

                                    <div className="p-4">
                                        <a className="d-block h5 lh-base mb-4" href="">Campanha Adote-me, será realizada neste mês...</a>
                                        <p>How to cultivate organic fruits and vegetables in own firm</p>
                                        <div className="text-muted border-top pt-4">
                                            <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                            <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>01 Jan, 2023</small>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="product-item shadow ">
                                    <img className="img-fluid mt-2 w-100" src={post3} alt="" />

                                    <div className="p-4">
                                        <a className="d-block h5 lh-base mb-4" href="">Campanha Adote-me, será realizada neste mês...</a>
                                        <p>How to cultivate organic fruits and vegetables in own firm</p>
                                        <div className="text-muted border-top pt-4">
                                            <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                            <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>01 Jan, 2023</small>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="product-item shadow ">
                                    <img className="img-fluid mt-2 w-100" src={post3} alt="" />

                                    <div className="p-4">
                                        <a className="d-block h5 lh-base mb-4" href="">Campanha Adote-me, será realizada neste mês...</a>
                                        <p>How to cultivate organic fruits and vegetables in own firm</p>
                                        <div className="text-muted border-top pt-4">
                                            <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                            <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>01 Jan, 2023</small>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="product-item shadow ">
                                    <img className="img-fluid mt-2 w-100" src={post3} alt="" />

                                    <div className="p-4">
                                        <a className="d-block h5 lh-base mb-4" href="">Campanha Adote-me, será realizada neste mês...</a>
                                        <p>How to cultivate organic fruits and vegetables in own firm</p>
                                        <div className="text-muted border-top pt-4">
                                            <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                            <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>01 Jan, 2023</small>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="product-item shadow ">
                                    <img className="img-fluid mt-2 w-100" src={post3} alt="" />

                                    <div className="p-4">
                                        <a className="d-block h5 lh-base mb-4" href="">Campanha Adote-me, será realizada neste mês...</a>
                                        <p>How to cultivate organic fruits and vegetables in own firm</p>
                                        <div className="text-muted border-top pt-4">
                                            <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                            <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>01 Jan, 2023</small>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="product-item shadow ">
                                    <img className="img-fluid mt-2 w-100" src={post3} alt="" />

                                    <div className="p-4">
                                        <a className="d-block h5 lh-base mb-4" href="">Campanha Adote-me, será realizada neste mês...</a>
                                        <p>How to cultivate organic fruits and vegetables in own firm</p>
                                        <div className="text-muted border-top pt-4">
                                            <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                            <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>01 Jan, 2023</small>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="product-item shadow ">
                                    <img className="img-fluid mt-2 w-100" src={post3} alt="" />

                                    <div className="p-4">
                                        <a className="d-block h5 lh-base mb-4" href="">Campanha Adote-me, será realizada neste mês...</a>
                                        <p>How to cultivate organic fruits and vegetables in own firm</p>
                                        <div className="text-muted border-top pt-4">
                                            <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                            <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>01 Jan, 2023</small>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="product-item shadow ">
                                    <img className="img-fluid mt-2 w-100" src={post3} alt="" />

                                    <div className="p-4">
                                        <a className="d-block h5 lh-base mb-4" href="">Campanha Adote-me, será realizada neste mês...</a>
                                        <p>How to cultivate organic fruits and vegetables in own firm</p>
                                        <div className="text-muted border-top pt-4">
                                            <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                            <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>01 Jan, 2023</small>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="col-lg-4 col-md-4 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="product-item shadow ">
                                    <img className="img-fluid mt-2 w-100" src={post3} alt="" />

                                    <div className="p-4">
                                        <a className="d-block h5 lh-base mb-4" href="">Campanha Adote-me, será realizada neste mês...</a>
                                        <p>How to cultivate organic fruits and vegetables in own firm</p>
                                        <div className="text-muted border-top pt-4">
                                            <small className="me-3"><i className="fa fa-user text-primary me-2"></i>Admin</small>
                                            <small className="me-3"><i className="fa fa-calendar text-primary me-2"></i>01 Jan, 2023</small>
                                        </div>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>

                    <div className="col-12 mt-5 text-center wow fadeInUp" data-wow-delay="0.1s">
                        <button id="carrega-mais" className="btn btn-primary rounded-pill py-3 px-5">Veja mais</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blog