import React from "react"

//Imagens
import remedio from "./../../assets/imagens/icones/remedio.svg"
import dogfood from "./../../assets/imagens/icones/dog-food.svg"
import veterinaria from "./../../assets/imagens/icones/veterinarian.svg"

function Services() {
    return (
        <>
            <div className="container-fluid bg-icon py-6" id="servicos">
                <div className="container p-5">
                    <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "500px"}}>
                        <h1 className="display-5 mb-3">🐾Nossos serviços🐾</h1>
                        <p>
                            Vivemos de doações, nossos recursos são limtados, as nossas buscas
                            por animais que vivem em situações de abandono, precisam de cuidados
                            veterinários e contamos com as doações de todos.
                        </p>
                    </div>

                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="bg-white shadow br text-center h-100 p-4 p-xl-5">
                                <img className="w-50 img-fluid mb-4" src={remedio} alt="Medicações" />
                                <h4 className="mb-3">Medicações</h4>
                                <p className="mb-4">Tratamos os animais com vermifungos para que possam ser cuidados pelo adotante.</p>
                                <a className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" href="/#">Leia Mais <span aria-hidden="true">→</span></a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="bg-white shadow br text-center h-100 p-4 p-xl-5">
                                <img className="w-50 img-fluid mb-4" src={dogfood} alt="Alimentação" />
                                <h4 className="mb-3">Nutrição</h4>
                                <p className="mb-4">Alimentamos os bichinhos para que eles possam se fortalecer do quadro de desnutrição.</p>
                                <a className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" href="/#">Leia Mais <span aria-hidden="true">→</span></a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="bg-white shadow br text-center h-100 p-4 p-xl-5">
                                <img className="w-50 img-fluid mb-4" src={veterinaria} alt="Veterinário" />
                                <h4 className="mb-3">Veterinários</h4>
                                <p className="mb-4">Contamos com ajuda de doações para que os bichinhos possam ser tratados pelos veterinários.</p>
                                <a className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" href="/#">Leia Mais <span aria-hidden="true">→</span></a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Services