import React, { useEffect } from "react"
import $ from "jquery"

//Imagens
import logo from "./../../assets/imagens/logo.png"


import ReactOwlCarousel from "react-owl-carousel";
//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel/dist/assets/owl.theme.default.css';

const options = {
    items: 3,
    autoplay: true,
    smartSpeed: 1000,
    margin: 25,
    loop: true,
    center: true,
    dots: false,
    nav: true,
    navText: [
        '<i classNameName="bi bi-chevron-left text-black">&#129120;</i>',
        '<i classNameName="bi bi-chevron-right text-black">&#129122;</i>'
    ],
    responsiveClass: true,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 3,

        }
    },
};


function Testimonials() {

    return (
        <>
            <div className="container-fluid bg-light bg-icon py-6 mb-5" id="testimonials">
                <div className="container p-5">
                    <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "500px" }}>
                        <h1 className="display-5 mb-3">🐾 Depoimentos 🐾</h1>
                        <p>Tempor ut dolore lorem kasd vero ipsum sit eirmod sit. Ipsum diam justo sed rebum vero dolor duo.</p>
                    </div>

                    <ReactOwlCarousel loop nav {...options} margin={10} className="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.3s">
                        <div className="testimonial-item position-relative bg-white p-5 mt-4">
                            <i className="fa fa-quote-left fa-3x text-primary position-absolute top-0 start-0 mt-n4 ms-5"></i>
                            <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
                            <div className="d-flex align-items-center">
                                <img className="flex-shrink-0 rounded-circle" src={logo} alt="" />
                                <div className="ms-3">
                                    <h5 className="mb-1">Tutor Name</h5>
                                    <span>Profession</span>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-item position-relative bg-white p-5 mt-4">
                            <i className="fa fa-quote-left fa-3x text-primary position-absolute top-0 start-0 mt-n4 ms-5"></i>
                            <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
                            <div className="d-flex align-items-center">
                                <img className="flex-shrink-0 rounded-circle" src={logo} alt="" />
                                <div className="ms-3">
                                    <h5 className="mb-1">Tutor Name</h5>
                                    <span>Profession</span>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-item position-relative bg-white p-5 mt-4">
                            <i className="fa fa-quote-left fa-3x text-primary position-absolute top-0 start-0 mt-n4 ms-5"></i>
                            <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
                            <div className="d-flex align-items-center">
                                <img className="flex-shrink-0 rounded-circle" src={logo} alt="" />
                                <div className="ms-3">
                                    <h5 className="mb-1">Tutor Name</h5>
                                    <span>Profession</span>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-item position-relative bg-white p-5 mt-4">
                            <i className="fa fa-quote-left fa-3x text-primary position-absolute top-0 start-0 mt-n4 ms-5"></i>
                            <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
                            <div className="d-flex align-items-center">
                                <img className="flex-shrink-0 rounded-circle" src={logo} alt="" />
                                <div className="ms-3">
                                    <h5 className="mb-1">Tutor Name</h5>
                                    <span>Profession</span>
                                </div>
                            </div>
                        </div>
                    </ReactOwlCarousel>

                </div>
            </div>
        </>
    )
}

export default Testimonials