import React from "react"

function Newsletter() {
    return (
        <>
            <div className="container-fluid newsletter bg-secondary bg-icon mt-5 py-6 wow fadeIn">
                <h1 className="section-title text-white mb-4"><span></span>Newsletter<span></span></h1>
                <h5 className="text-white">Fique por dentro das novidades</h5>
                <div className="position-relative w-100 mt-3">
                    <input
                        className="form-control border-0 rounded-pill w-100 ps-4 pe-5"
                        type="text"
                        placeholder="Digite seu E-mail..."
                        style={{height: "48px"}}
                    />
                    <button
                        type="button"
                        className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"
                    >
                        <i className="fa fa-paper-plane text-primary fs-4"></i>
                    </button>
                </div>
            </div>
        </>
    )
}

export default Newsletter