import React from "react"

//Imagem
import logo from "./../assets/imagens/logo1.png"
//import login from "./../assets/imagens/login.svg"


function Login() {
    return (
        <>
            <div className="container-fluid bg-light">
                <div className="row-login vh-100 vw-100 p-5">
                    <div className="left-login d-flex justify-content-center align-items-center">
                        <div className="col-md-8 card shadow">
                            <img src={logo} alt="Logo" />
                            <form className="form">
                                <div className="form-group" label-for="Email">
                                    <label className="form-label d-flex justify-content-between" label-for="email">E-mail</label>

                                    <input className="form-input form-control" id="email" type="email" placeholder="Seu e-mail" autocomplete="off" model="form.email" required />
                                </div>

                                <div className="form-group" label-for="password">
                                    <label className="form-label d-flex justify-content-between">
                                        Senha
                                    </label>

                                    <input className="form-input form-control" id="password" type="password" placeholder="Digite sua senha" model="form.password" required/>
                                </div>

                                <button className="btn-login btn btn-secondary" type="submit" variant="primary" block click="login">
                                    <i className="fas fa-sign-in-alt"></i> 
                                    Entrar
                                </button>
                                <hr />
                            </form>

                            <small><a className="text-center p-2" href="/">Esqueceu sua senha?</a></small>
                        </div>

                        <div className="col-md-6 img-login">
                            <h4 className="card-title mb-4 text-center">ADM - Amada São Luís-MA</h4>
                            {/*<img src={login} alt="Login" className="img-login" />*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login