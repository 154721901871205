import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

//Styles
import './assets/css/style.css';

//Pages
import Home from './pages/Home';
import Login from './pages/Login';
import Contato from './pages/Contato';
import Posts from './pages/Posts';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/login' element={<Login/>} />
          <Route path='/contato' element={<Contato/>} />
          <Route path='/posts' element={<Posts/>}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
