import React from "react"

function Faq() {
    return (
        <>
            <section id="faq" className="faq">
                <div className="container">
                    <div>
                        <h2>Perguntas frequentes?</h2>
                    </div>

                    <div className="faq-list">
                        <ul>
                            <li className="active">
                                <i className="fa fa-question-circle icon-help" aria-hidden="true"></i>

                                <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-1">
                                    A Adoção é paga?
                                    <i className="fa fa-sm fa-angle-down icon-show" aria-hidden="true"></i>
                                    <i className="fa fa-sm fa-angle-up icon-close"></i>
                                </a>
                                <div id="faq-list-1" className="collapse show" data-bs-parent=".faq-list">
                                    <p>
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <i className="fa fa-question-circle icon-help" aria-hidden="true"></i>
                                <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" className="collapsed">
                                    Preciso vermifungar o pet?
                                    <i className="fa fa-sm fa-angle-down icon-show" aria-hidden="true"></i>
                                    <i className="fa fa-sm fa-angle-up icon-close"></i>
                                </a>
                                <div id="faq-list-2" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <i className="fa fa-question-circle icon-help" aria-hidden="true"></i>
                                <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed">
                                    Como eu devo cuidar do Pet?
                                    <i className="fa fa-sm fa-angle-down icon-show" aria-hidden="true"></i>
                                    <i className="fa fa-sm fa-angle-up icon-close"></i>
                                </a>
                                <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    </p>
                                </div>
                            </li>

                            <li>
                                <i className="fa fa-question-circle icon-help" aria-hidden="true"></i>
                                <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" className="collapsed">
                                    Quais medicações posso comprar?
                                    <i className="fa fa-sm fa-angle-down icon-show" aria-hidden="true"></i>
                                    <i className="fa fa-sm fa-angle-up icon-close"></i>
                                </a>
                                <div id="faq-list-4" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    </p>
                                </div>
                            </li>

                        </ul>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Faq