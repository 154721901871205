import React, { useEffect } from "react"
import { NavLink } from "react-router-dom"
import $ from "jquery";

//Imagens
import logo from "./../../assets/imagens/logo1.png"

function Navbar() {

    useEffect(() => {
        if (typeof document !== "undefined") {
            $(window).scroll(function () {
                if ($(this).scrollTop() > 45) {
                    $(".navbar").addClass("sticky-top shadow-sm");
                } else {
                    $(".navbar").removeClass("sticky-top shadow-sm");
                }
            });
        }
    }, [])

    return (
        <>
            <div className="container-fluid position-relative p-0 m-0 wow fadeIn" data-wow-delay="0.1s">
                <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
                    <NavLink to="/" className="logo navbar-brand p-0 m-3">
                        <img className="img-fluid text-center" src={logo} alt="Amada São Luís" />
                    </NavLink>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="fa fa-bars"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav mx-auto py-0">
                            <a href="/" className="nav-item nav-link active">Home</a>
                            <a href="#about" className="nav-item nav-link">Amada</a>
                            <a href="#servicos" className="nav-item nav-link">Serviços</a>
                            <a href="#doacoes" className="nav-item nav-link">Doações</a>

                            <div className="nav-item dropdown">
                                <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Páginas</a>
                                <div className="dropdown-menu m-0">
                                    <a href="/posts" className="dropdown-item">Blog</a>
                                    <a href="/#gallery" className="dropdown-item">Galeria</a>
                                    <a href="/#testimonials" className="dropdown-item">Depoimentos</a>
                                </div>
                            </div>
                            <a href="/contato" className="nav-item nav-link">Contato</a>
                        </div>

                        <div className="navbar-nav mx-auto py-0">
                            <a href="https://sacola.pagseguro.uol.com.br/274bd794-7499-49f6-879f-386a1aed3774" className="btn rounded-pill py-2 px-4 ms-3 d-none d-lg-block" rel="noreferrer noopener" target="_blank">
                                <i className="fas fa-money-bill-wave-alt me-3 text-primary"></i>
                                Doação
                            </a>

                            <a href="/login" target="_blank" className="btn rounded-pill py-2 px-4 ms-3 d-none d-lg-block">
                                <i className="fas fa-sign-in-alt me-3 text-primary"></i>
                                Login
                            </a>

                            <a href="https://www.facebook.com/commerce/products/camisa-amada/1025298810897229/?ref=page_home_tab&referral_code=page_shop_card&locale=pt_BR" className="btn rounded-pill py-2 px-4 ms-3 d-none d-lg-block" rel="noreferrer noopener" target="_blank">
                                <i className="fa fa-shopping-cart me-3 text-primary"></i>
                                Loja
                            </a>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Navbar