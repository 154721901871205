import React from "react"
import { NavLink } from "react-router-dom"
import Navbar from "../components/Navbar/Navbar"
import Footer from "../components/Footer/Footer"
import ScrollToTop from "../components/BackToTop/ScrollToTop"

function Contato() {
    return (
        <>
            <Navbar />
            <div className="container-fluid bg-light page-header wow fadeIn" data-wow-delay="0.1s">
                <div className="container p-5">
                    <h1 className="display-3 mb-3 animated slideInDown">Contato</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                                <NavLink className="text-primary" to="/">Home</NavLink>
                            </li>
                            <li className="breadcrumb-item text-body active" aria-current="page">
                                Contato
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div className="container-xxl py-3">
                <div className="container p-5">
                    <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "500px" }}>
                        <h1 className="display-5mb-3">Fale Conosco</h1>
                        <p>
                            Envie sua mensagem para doar ou ser parceiro da Amada, aceitamos
                            voluntários.
                        </p>
                    </div>
                    <div className="row g-5 justify-content-center">
                        <div className="col-lg-5 col-md-12 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="bg-primary text-white d-flex flex-column justify-content-center h-100 p-5">
                                <h5 className="text-white">Telefone</h5>
                                <p className="mb-5">
                                    <i className="fa fa-phone-alt me-3"></i>+55 98 99975-1440
                                </p>
                                <h5 className="text-white">E-mail Contato</h5>
                                <p className="mb-5">
                                    <i className="fa fa-envelope me-3"></i> amada.sl@gmail.com
                                </p>
                                <h5 className="text-white">Doe</h5>
                                <p className="mb-5">
                                    <i className="fas fa-money-bill-wave-alt me-3"></i> PIX/CNPJ:
                                    07.772.466/0001-54 <br />
                                    BB: Ag 2972-6 C/C 118987-5 <br />
                                    Caixa: Ag 0027 Op 013 C/P 56186-9
                                </p>
                                <h5 className="text-white">Siga-nos nas Redes Sociais</h5>
                                <div className="d-flex pt-2">
                                    <a className="btn btn-square btn-outline-light rounded-circle me-1" href="/#"><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-square btn-outline-light rounded-circle me-1" href="/#"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-square btn-outline-light rounded-circle me-1" href="/#"><i className="fab fa-youtube"></i></a>
                                    <a className="btn btn-square btn-outline-light rounded-circle me-0" href="/#"><i className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                            <h1 className="mb-4 text-center mb-5">🐶Adote-nos!🐱</h1>
                            <form>
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="text" className="form-control" id="name" placeholder="Your Name" />
                                            <label htmlFor="name">Seu Nome</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="email" className="form-control" id="email" placeholder="Your Email" />
                                            <label htmlFor="email">Seu E-mail</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input type="text" className="form-control" id="subject" placeholder="Subject" />
                                            <label htmlFor="subject">Assunto</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea className="form-control" placeholder="Leave a message here" id="message" style={{ height: "200px" }}></textarea>
                                            <label htmlFor="message">Mensagem</label>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center mt-5">
                                        <button className="btn btn-primary rounded-pill py-3 px-5" type="submit">
                                            Enviar Mensagem
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <ScrollToTop />
        </>
    )
}

export default Contato