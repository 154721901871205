import React from "react"

//Imgens
import patinhas from "./../../assets/imagens/bg-patinhas2.png"
import adote from "./../../assets/imagens/bg-adote-3.png"
import Faq from "../Faq/Faq"


function About() {

    return (
        <>
            <div className="container-xxl p-0 wow fadeInUp" data-wow-delay="0.1s" id="about">
                <img className="img-fluid w-100" src={patinhas} />

                <div className="container">
                    <div className="section-header wow fadeInUp" data-wow-delay="0.3s">
                        <h1 className="section-title text-start justify-content-center">🐾 A Amada 🐾</h1>
                        <p className="section-title p-5 text-secondary text-start justify-content-center">
                            Amada Associação Maranhnese em defesa dos animais.
                        </p>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-lg-6 p-5 wow fadeIn" data-wow-delay="0.5s">
                            <div className="about-img position-relative overflow-hidden p-0 pe-0">
                                <img className="img-fluid w-100" src={adote} />
                            </div>
                        </div>

                        <div className="col-lg-6 p-5 wow fadeIn" data-wow-delay="0.5s">
                            <div className="faq-container py-3">
                                <h2 className="text-center p-3" id="faq">FAQ</h2>
                                <Faq />
                            </div>

                            <div className="text-center">
                                <a className="btn btn-primary  rounded-pill py-3 px-5 mt-5" target="_blank" href="https://sacola.pagseguro.uol.com.br/274bd794-7499-49f6-879f-386a1aed3774" rel="noreferrer noopener">Doações</a>
                            </div>
                        </div>
                    </div>
                </div>

                <img className="img-fluid w-100" src={patinhas} />
            </div>
        </>
    )
}

export default About