import React from "react"

//Imagens
import banner from "./../../assets/imagens/bg-carousel-3.png"
import banner2 from "./../../assets/imagens/bg-carousel-2.png"
import banner3 from "./../../assets/imagens/bg-carousel-4.png"
import banner4 from "./../../assets/imagens/bg-carousel-5.png"

function Header() {
    return (
        <>
            <div className="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.3s">
                <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="w-100" src={banner} alt="Banner" />

                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-start">
                                        <div className="col-lg-7">
                                            <h1 className="display-2 mb-5 animated slideInDown" data-wow-delay="0.1s">
                                                Trate o seu Pet com amor e carinho!
                                            </h1>
                                            <a href="/" className="btn btn-primary rounded-pill py-sm-3 px-sm-5">Adoção</a>
                                            <a href="/" className="btn btn-secondary rounded-pill py-sm-3 px-sm-5 ms-3">Doações</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="carousel-item">
                            <img className="w-100" src={banner2} alt="Image" />
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-start">
                                        <div className="col-lg-7">
                                            <h1 className="display-2 mb-5 animated slideInDown">
                                                Precisamos de Você, Adote um Bichinho.
                                            </h1>
                                            <a href="/" className="btn btn-primary rounded-pill py-sm-3 px-sm-5">Adoção</a>
                                            <a href="/" className="btn btn-secondary rounded-pill py-sm-3 px-sm-5 ms-3">Doações</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="carousel-item">
                            <img className="w-100" src={banner3} alt="Image" />
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-start">
                                        <div className="col-lg-7">
                                            <h1 className="display-2 mb-5 animated slideInDown">
                                                O Amor que você doa é o mesmo que recebe!
                                            </h1>
                                            <a href="/" className="btn btn-primary rounded-pill py-sm-3 px-sm-5">Adoção</a>
                                            <a href="/" className="btn btn-secondary rounded-pill py-sm-3 px-sm-5 ms-3">Doações</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="carousel-item">
                            <img className="w-100" src={banner4} alt="Image" />
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-start">
                                        <div className="col-lg-7">
                                            <h1 className="display-2 mb-5 animated slideInDown">
                                                Cada pequenino que adota é uma alegria!
                                            </h1>
                                            <a href="/" className="btn btn-primary rounded-pill py-sm-3 px-sm-5">Adoção</a>
                                            <a href="/" className="btn btn-secondary rounded-pill py-sm-3 px-sm-5 ms-3">Doações</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button className="carousel-control-prev" slot="prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>

                    <button className="carousel-control-next" slot="next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default Header