import React from "react"

//Components
import Navbar from "../components/Navbar/Navbar"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import ScrollToTop from "../components/BackToTop/ScrollToTop"
import Spinner from "../components/Spinner/Spinner"
import About from "../components/About/About"
import Services from "../components/Services/Services"
import BannerInfo from "../components/BannerInfo/BannerInfo"
import Testimonials from "../components/Depoiments/Testimonials"
import Shopping from "../components/ShoppingPet/Shopping"
import Newsletter from "../components/Newsletter/Newsletter"
import Blog from "../components/Blog/Blog"
import Gallery from "../components/Gallery/Gallery"

function Home() {
  return (
    <>
      <Spinner/>
      <Navbar />
      <Header/>
      <About/>
      <Gallery/>
      <Services/>
      <BannerInfo/>
      <Testimonials/>
      <Shopping/>
      <Newsletter/>
      <Blog/>
      <Footer/>
      <ScrollToTop/>
    </>
  )
}

export default Home